import React from 'react'
import './Table.css'

const Table = (props) => {

	let tableClass = "tableWrapper"
	if (props.hl) {
		tableClass = "tableWrapper hl"
	}

  const listItems = props.data.map((lesson, index) => { 
    let gCh, wCh
    (props.group) ? gCh = 2 : gCh = 1;
    (props.week) ? wCh = 'odd' : wCh = 'even';

    if ((gCh === lesson.group || lesson.group === 0) && (wCh === lesson.week || lesson.week === 'all') && (props.days === lesson.day)) {
      return (
        <div className="singularLesson" key={index}>
          <table>
            <tr>
              <td type={lesson.type}>{lesson.start_time}</td>
              <td>{lesson.title}</td>
            </tr>
            <tr>
              <td type={lesson.type}>{lesson.end_time}</td>
              <td>{lesson.type}</td>
            </tr>
            <tr>
              <td type={lesson.type}>{"Ауд. " + lesson.cabinet}</td>
              <td>{lesson.teacher}</td>
            </tr>
          </table>
        </div> 
      )
    } else {
      return null;
    }
  })

  return(
    <div className={tableClass}>
    {listItems}
    </div>
  )
}


export default Table;