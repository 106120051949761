import firebase from 'firebase'

const firebaseConfig = {
  apiKey: "AIzaSyDXcbDaIcMY0Oy2RcmlzcGVNBs37v8sd20",
  authDomain: "kemsu-schedule.firebaseapp.com",
  databaseURL: "https://kemsu-schedule.firebaseio.com",
  projectId: "kemsu-schedule",
  storageBucket: "kemsu-schedule.appspot.com",
  messagingSenderId: "991914132922",
  appId: "1:991914132922:web:9e09d3ca2b1559a5d5c983"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export default firebase;