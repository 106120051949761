import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

export default function SelectDay(props) {
  return (
    <div className="buttons" >
      <ThemeProvider theme={theme}>
        <ButtonGroup color="primary" variant="contained" aria-label="contained primary button group">
          <Button onClick={props.choose1} color={props.currDay == 1 ? "secondary" : "primary"} >ПН</Button>
          <Button onClick={props.choose2} color={props.currDay == 2 ? "secondary" : "primary"} >ВТ</Button>
          <Button onClick={props.choose3} color={props.currDay == 3 ? "secondary" : "primary"} >СР</Button>
          <Button onClick={props.choose4} color={props.currDay == 4 ? "secondary" : "primary"} >ЧТ</Button>
          <Button onClick={props.choose5} color={props.currDay == 5 ? "secondary" : "primary"} >ПТ</Button>
        </ButtonGroup>
      </ThemeProvider>
    </div>
  );
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4C566A"
    }
  }
})