import React from 'react';
import Table from './Table';
import './pcTable.css'

const PCTable = (props) => {
  return (
    <div className="compooterTableWrapper" >
      <Table className="t1" data={props.data} group={props.group} week={props.week} days={1} hl={props.days === 1 ? true : false} />
      <Table className="t2" data={props.data} group={props.group} week={props.week} days={2} hl={props.days === 2 ? true : false}/>
      <Table className="t3" data={props.data} group={props.group} week={props.week} days={3} hl={props.days === 3 ? true : false}/>
      <Table className="t4" data={props.data} group={props.group} week={props.week} days={4} hl={props.days === 4 ? true : false}/>
      <Table className="t5" data={props.data} group={props.group} week={props.week} days={5} hl={props.days === 5 ? true : false}/>
    </div>
    
  )
}


export default PCTable;