import React from 'react';
import './Toolbar.css'
import GroupMenu from '../GroupMenu/GroupMenu';


const toolbar = props => (
  <header className="toolbar">
    <nav className="toolbar_navigation">
      <div className="toolbar_logo"><a href="/"><img height="42" weight="42" src="KemSULogo.png" alt=""/></a></div>
      <div className="spacer" />
        <GroupMenu group={props.group} handleGroup={props.handleGroup}/>
      <div className="toolbar_navigation-items">
        {props.week ? "Нечётная" : "Чётная"} неделя, {props.dayText}
      </div>
    </nav>
  </header>
);

export default toolbar;