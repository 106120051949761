import React, { useState } from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { Menu, MenuItem, Button } from '@material-ui/core';
import "./GroupMenu.css"

export default function GroupMenu(props) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setAnchorEl(null);
    props.handleGroup(e)
  };

  return (
    <div className="groupMenu">
      <ThemeProvider theme={theme}>
        <Button style={{ fontSize: '1rem', padding: '6px 10px', paddingTop: '5px', paddingBottom: '3px' }} aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} color="primary">
          {props.group === "204" ? "ФИТ-204" : "МОА-205"}
        </Button>
        <Menu
          color="primary"
          variant="selectedMenu"
          id="menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={props.handleClose}
        >
          <MenuItem value="204" color="primary" selected={props.group === "204" ? true : false} onClick={handleClose}>ФИТ-204</MenuItem>
          <MenuItem value="205" color="primary" selected={props.group === "205" ? true : false} onClick={handleClose}>МОА-205</MenuItem>
        </Menu>
      </ThemeProvider>
    </div>
  )
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#ffffff"
    }
  }
})