import React from 'react';
import Switch from '@material-ui/core/Switch';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';

const WeekSwitch = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <Switch
        color="primary"
        disableRipple="true"
        checked={props.weekChoice}
        onChange={props.onChange}
        name="weekChoice"
        inputProps={{ 'aria-label': 'week checkbox' }}
      />
    </ThemeProvider>
  );
};

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#D8DEE9"
    }
  }
})

export default WeekSwitch;
