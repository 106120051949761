import React from 'react';
import firebase from './firebase';
import './App.css';
import Toolbar from './components/Toolbar/Toolbar';
import Backdrop from './components/Backdrop/Backdrop';
import Table from './components/Table/Table';
import GroupSwitch from './components/SchSwitch/groupSwitch'
import WeekSwitch from './components/SchSwitch/weekSwitch'
import SelectDay from './components/SelectDay/SelectDay'
import PCTable from './components/Table/pcTable'
import { isMobile } from 'react-device-detect'
import currentWeekNumber from 'current-week-number'
import Snowfall from 'react-snowfall';


class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      gChoice: window.localStorage.getItem("storedGroup") || false,
      wChoice: false,
      group: window.localStorage.getItem("Group") || "204",
      selectedDay: 1,
      dayText: "",
      weekOddity: false,
    };
    this.groupHandler = this.groupHandler.bind(this);
    this.weekHandler = this.weekHandler.bind(this);
    this.drawerToggleClickHandler = this.drawerToggleClickHandler.bind(this);
    this.backdropClickHandler = this.backdropClickHandler.bind(this);
    this.dayHandler1 = this.dayHandler1.bind(this);
    this.dayHandler2 = this.dayHandler2.bind(this);
    this.dayHandler3 = this.dayHandler3.bind(this);
    this.dayHandler4 = this.dayHandler4.bind(this);
    this.dayHandler5 = this.dayHandler5.bind(this);
  }

  componentDidMount() {
    const fetchData = async () => {
      const collec = (this.state.group === "204") ? "lessons" : "lessons205";
      const db = firebase.firestore();
      const data = await db.collection(collec).get();
      this.setState({ lessons: data.docs.map((doc) => doc.data()) });
    };
    fetchData();

    if ((currentWeekNumber() + (currentWeekNumber("12/31/2020") - currentWeekNumber('08/30/2020'))) % 2 === 1) {
      this.setState({ wChoice: true, weekOddity: true })
    }

    let d = new Date();
    let dayArr = [1, 1, 2, 3, 4, 5, 1];
    let dayArr2 = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    let asd = [7, 1, 2, 3, 4, 5, 6];
    this.setState({ selectedDay: dayArr[d.getDay()] })
    this.setState({ dayText: dayArr2[d.getDay()] })

    if (asd[d.getDay()] === 6 || asd[d.getDay()] === 7) {
      this.setState((prevState) => {
        return {
          wChoice: !prevState.wChoice}
      });
    }

    this.setState({ gChoice: (window.localStorage.getItem('storedGroup') === "true") || false })
    this.setState({ group: (window.localStorage.getItem('Group') === "204") ? "204" : "205"})
  }

  componentDidUpdate() {
    window.localStorage.setItem('storedGroup', this.state.gChoice);
    window.localStorage.setItem('Group', this.state.group);
  }

  drawerToggleClickHandler = () => {
    this.setState((prevState) => {
      return {
        drawerOpen: !prevState.drawerOpen,
      };
    });
  };

  backdropClickHandler = () => {
    this.setState({ drawerOpen: false });
  };

  groupHandler = () => {
    this.setState((prevState) => {
      return { gChoice: !prevState.gChoice }
    })
  };

  handleGroup = (e) => {
    this.setState({ group: e.currentTarget.value });
    window.location.reload(false);
  };

  weekHandler = () => {
    this.setState((prevState) => {
      return { wChoice: !prevState.wChoice }
    })
  }

  // doesn't work properly 
  // dayHandler = (event) => {
  //   this.setState({ selectedDay: parseInt(event.target.value, 10) }, function() { console.log('bruh') })
  // }

  // because reasons
  dayHandler1 = (event) => {
    this.setState({ selectedDay: 1 })
  }
  dayHandler2 = (event) => {
    this.setState({ selectedDay: 2 })
  }
  dayHandler3 = (event) => {
    this.setState({ selectedDay: 3 })
  }
  dayHandler4 = (event) => {
    this.setState({ selectedDay: 4 })
  }
  dayHandler5 = (event) => {
    this.setState({ selectedDay: 5 })
  }


  render() {
    let backdrop;
    if (this.state.drawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />;
    }

    
    let iftable;
    let ifDay;
    let snowCount;
    if (isMobile) {
      snowCount = 50;
      iftable = <Table data={this.state.lessons} group={this.state.gChoice} week={this.state.wChoice} days={this.state.selectedDay}/>;
      ifDay = <SelectDay currDay={this.state.selectedDay} choose1={this.dayHandler1} choose2={this.dayHandler2} choose3={this.dayHandler3} choose4={this.dayHandler4} choose5={this.dayHandler5}  />;
    } else {
      snowCount = 100;
      iftable = <PCTable data={this.state.lessons} group={this.state.gChoice} week={this.state.wChoice} days={this.state.selectedDay}/>;
    }

    let m = new Date().getMonth();
    let winter = [11, 0, 1];
    let snow = (winter.includes(m)) ? <Snowfall snowflakeCount={snowCount}/> : null;

    return (
      <div style={{ height: '100%' }}>
        {snow}
        <Toolbar week={this.state.weekOddity} dayText={this.state.dayText} group={this.state.group} handleGroup={this.handleGroup}/>
        {/* <Drawer show={this.state.drawerOpen} /> */}
        {backdrop}
        <center>
          <main>
            <div className="switchWrapper">
              1 гр. <GroupSwitch onChange={this.groupHandler} groupChoice={this.state.gChoice} /> 2гр. <br></br>
              чёт <WeekSwitch onChange={this.weekHandler} weekChoice={this.state.wChoice} /> нечёт
            </div>
              {ifDay}
              {iftable}
          </main>
        </center>
      </div>
    );
  }
}

export default App;
